import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { updateBidApply, selectBidType, selectBidApplyById, updateToBidApply, setPauseOrStart } from '@/api/contract';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie"; // import moment from 'moment';

export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined
  },
  props: {
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      fileUrl: [],
      itemId: null,
      bidTypeId: null,
      customerName: null,
      projectName: null,
      bidScope: null,
      bidDate: null,
      bidAmount: null,
      estimatedProfit: null,
      mainCompetitors: [],
      mainRisk: [],
      id: null,
      content: null,
      hyApproachApprovals: [],
      approvalDtos: [],
      bidBond: null,
      bidCost: null
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      bidTypeId: [{
        required: true,
        message: '投标类别不能为空',
        trigger: 'change',
        type: 'number'
      }],
      customerName: [{
        required: true,
        message: '客户名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      projectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      bidScope: [{
        required: true,
        message: '招标范围不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      bidAmount: [{
        required: true,
        message: '预估投标金额不能为空',
        trigger: 'blur',
        type: 'number'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      estimatedProfit: [{
        required: true,
        message: '预估利润不能为空',
        trigger: 'blur',
        type: 'number'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      bidDate: [{
        required: true,
        message: '投标时间不能为空',
        trigger: 'change'
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.fileUrl.length == 0
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };
    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return false;
      }
    }); //获取附件的url

    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.fileUrl = val;
    };

    const loading = ref(false);
    const loading1 = ref(false); //提交

    const handleOk = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.mainCompetitors = JSON.stringify(obj.mainCompetitors.filter(item => item != null));
        obj.mainRisk = JSON.stringify(obj.mainRisk.filter(item => item != null));
        loading.value = true;
        let res = await updateBidApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; // const disabledDate = current => {
    //   return current && current <= moment().subtract(1, 'days');
    // };
    //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 17,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const contractTypeName = ref([]); //获取类别

    const selectLabelByParam1 = async () => {
      let res = await selectBidType({
        bidTypeName: ''
      });

      if (res.code === 200) {
        contractTypeName.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            contractTypeName.value.push({
              value: item.id,
              label: item.bidTypeName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const deleteMinCompetitors = key => {
      formState.mainCompetitors.splice(key, 1);
    };

    const addMinCompetitors = () => {
      formState.mainCompetitors.push(null);
    };

    const deleteMainRisk = key => {
      formState.mainRisk.splice(key, 1);
    };

    const addMainRisk = () => {
      formState.mainRisk.push(null);
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "审批责任人";

        case 4:
          return "财务审批人";

        case 5:
          return "人事审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    const getData = async key => {
      const res = await selectBidApplyById({
        id: key
      });

      try {
        Object.assign(formState, res.data);
        formState.fileUrl = JSON.parse(formState.fileUrl);
        formState.mainCompetitors = JSON.parse(formState.mainCompetitors);
        formState.mainRisk = JSON.parse(formState.mainRisk);
        console.log(formState);
      } catch (error) {
        console.log('投标详情:', error);
      }
    }; //审批


    const handlePassOrReject = async num => {
      if (!formState.content) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        applyId: formState.id,
        content: formState.content,
        state: num,
        belongProject: formState.belongProject
      };

      if (num == 0) {
        loading1.value = true;
      } else {
        loading.value = true;
      }

      try {
        const res = await updateToBidApply(param);

        if (res.code == 200) {
          _message.success('审批成功！');

          loading.value = false;
          loading1.value = false;
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
          loading1.value = false;
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
        loading1.value = false;
      }
    }; //暂停


    const loading2 = ref(false);

    const handlePause = async () => {
      if (!formState.content) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        applyId: formState.id,
        content: formState.content,
        state: 4,
        belongProject: formState.belongProject
      };
      loading2.value = true;

      try {
        const res = await setPauseOrStart(param);

        if (res.code == 200) {
          _message.success(res.message);

          loading2.value = false;
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading2.value = false;
        }
      } catch (error) {
        console.log(error);
        loading2.value = false;
      }
    };

    onMounted(() => {
      getData(props.applyDetailData.key);
      getApprovalItem();
      selectLabelByParam1();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      getAdjuncts,
      // disabledDate,
      projectOptions,
      deleteMinCompetitors,
      addMinCompetitors,
      deleteMainRisk,
      addMainRisk,
      getCurrent,
      setRoleType,
      setStatus,
      isDisabledForCl,
      loading1,
      handlePassOrReject,
      handlePause,
      loading2,
      contractTypeName
    };
  }

});