import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRefs, nextTick, onMounted } from 'vue';
import { PlusOutlined, CloseOutlined, StarFilled } from '@ant-design/icons-vue';
import { selectBidType, addBidType, deleteBidType } from "/src/api/contract";
export default defineComponent({
  components: {
    PlusOutlined,
    CloseOutlined,
    StarFilled
  },
  emits: ['labelData'],

  setup(props, context) {
    onMounted(() => {
      selectLabelByParam1();
    });
    const inputRef = ref();
    const state = reactive({
      tags: [],
      inputVisible: false,
      inputValue: ''
    });

    const handleClose = id => {
      deleteLabel(id);
    };

    const showInput = () => {
      state.inputVisible = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    };

    const handleInputConfirm = () => {
      const inputValue = state.inputValue;
      let tags = state.tags;
      if (inputValue) addLabel(inputValue);
      Object.assign(state, {
        tags,
        inputVisible: false,
        inputValue: ''
      });
    }; //获取标签


    const selectLabelByParam1 = async () => {
      let res = await selectBidType({
        bidTypeName: ''
      });

      if (res.code === 200) {
        state.tags = [];
        res.data.forEach(item => {
          state.tags.push({
            id: item.id,
            label: item.bidTypeName,
            visible: true
          });
        }); // console.log(state.tags)

        context.emit('labelData', state.tags);
      } else {
        _message.error(res.message);
      }
    }; //新增标签


    const addLabel = async bidTypeName => {
      let res = await addBidType({
        bidTypeName
      });

      if (res.code === 200) {
        _message.success(res.message);

        selectLabelByParam1();
      } else {
        _message.error(res.message);
      }
    }; //删除标签


    const deleteLabel = async id => {
      let res = await deleteBidType({
        id
      });

      if (res.code === 200) {
        _message.success('删除成功');

        selectLabelByParam1();
      } else {
        _message.error(res.message);
      }
    };

    const handleChange = val => {
      state.tags.forEach(item => {
        if (item.id === val) {
          item.visible = !item.visible;
          console.log(item);
        }
      });
      console.log(val);
    };

    return { ...toRefs(state),
      handleClose,
      showInput,
      handleInputConfirm,
      inputRef,
      handleChange
    };
  }

});