import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { insertBidApply, selectProjectContractByParam, selectTeamMember, selectBidType } from '@/api/contract';
import { selectApprovalItem, selectProfessionalByItem } from '@/api/material';
import Cookies from "js-cookie"; // import moment from 'moment';

export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      fileUrl: [],
      itemId: null,
      bidTypeId: null,
      customerName: null,
      projectName: null,
      bidScope: null,
      bidDate: null,
      bidAmount: null,
      estimatedProfit: null,
      mainCompetitors: [],
      mainRisk: [],
      bidBond: null,
      bidCost: null
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      bidTypeId: [{
        required: true,
        message: '投标类别不能为空',
        trigger: 'change',
        type: 'number'
      }],
      customerName: [{
        required: true,
        message: '客户名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      projectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      bidScope: [{
        required: true,
        message: '招标范围不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      bidAmount: [{
        required: true,
        message: '预估投标金额不能为空',
        trigger: 'blur',
        type: 'number'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      estimatedProfit: [{
        required: true,
        message: '预估利润不能为空',
        trigger: 'blur',
        type: 'number'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      bidDate: [{
        required: true,
        message: '投标时间不能为空',
        trigger: 'change'
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.fileUrl.length == 0
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //获取附件的url

    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.fileUrl = val;
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.mainCompetitors = JSON.stringify(obj.mainCompetitors.filter(item => item != null));
        obj.mainRisk = JSON.stringify(obj.mainRisk.filter(item => item != null));
        loading.value = true;
        let res = await insertBidApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 17,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const contractTypeName = ref([]); //获取类别

    const selectLabelByParam1 = async () => {
      let res = await selectBidType({
        bidTypeName: ''
      });

      if (res.code === 200) {
        contractTypeName.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            contractTypeName.value.push({
              value: item.id,
              label: item.bidTypeName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const deleteMinCompetitors = key => {
      formState.mainCompetitors.splice(key, 1);
    };

    const addMinCompetitors = () => {
      formState.mainCompetitors.push(null);
    };

    const deleteMainRisk = key => {
      formState.mainRisk.splice(key, 1);
    };

    const addMainRisk = () => {
      formState.mainRisk.push(null);
    };

    onMounted(() => {
      getApprovalItem();
      selectLabelByParam1();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      getAdjuncts,
      // disabledDate,
      projectOptions,
      deleteMinCompetitors,
      addMinCompetitors,
      deleteMainRisk,
      addMainRisk,
      contractTypeName
    };
  }

});